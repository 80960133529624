import LinkList from 'components/contentBlocks/LinkList';
import PropTypes from 'prop-types';
import React from 'react';
import transformLink from 'libs/wordpress/utils/transformLink';

const LinkListBlock = ({ content = {} }) => {
    const { links = [] } = content;

    const transformedlinks = links.map(({ link }) => ({ link: transformLink(link) }));

    return <LinkList links={transformedlinks} />;
};

LinkListBlock.propTypes = {
    content: PropTypes.shape({
        links: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
};

export default LinkListBlock;
