import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { imageProp } from 'utils/proptypes/modules/mediaProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    .link-list-link:not(:last-child) {
        .link-list-heading {
            ::after {
                content: ' /';
            }
        }
    }
`;

const StyledLink = styled(Link)`
    .link-list-heading:hover {
        color: var(--dynamic-color-active);
        transition: color ${transitions.primary};
    }
`;

const LinkList = ({ links = [] }) => {
    if (links.length === 0) {
        return null;
    }

    return (
        <Wrapper
            display="flex"
            flexWrap="wrap"
            gap={['8px 12px', null, '16px 12px', null, '24px 12px']}
            justifyContent="flex-start"
        >
            {links.map(item => {
                const link = item.link;

                return (
                    <StyledLink className="link-list-link" key={link.label} to={link.to}>
                        <Heading
                            className="link-list-heading"
                            fontKeys={['Recife Display/24_120', null, 'Recife Display/48', null, 'Recife Display/64']}
                        >
                            {link.label}
                        </Heading>
                    </StyledLink>
                );
            })}
        </Wrapper>
    );
};

LinkList.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.exact({
            image: imageProp,
            link: PropTypes.exact({
                label: PropTypes.string,
                to: PropTypes.string,
            }),
        })
    ),
};

export default LinkList;
