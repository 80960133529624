import LinkListBlock from 'libs/wordpress/content/cmsBlocks/LinkListBlock';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import React from 'react';
import moduleSpacing from 'config/theme/moduleSpacing';

const LinkListModule = ({ ...rest }) => (
    <MaxWidthWrapper includeContentSpacing as="section" py={moduleSpacing.md}>
        <LinkListBlock {...rest} />
    </MaxWidthWrapper>
);

export default LinkListModule;
